import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import { Card } from 'reactstrap';

import { SEO } from '@components';
import { BlogEntryProps } from '@types';

const PaintJob = ({ includeSeo }: BlogEntryProps) => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(name: {eq: "PaintJob"}) {
          publicURL
        }
        damage: file(name: {eq: "teenage-stupidity"}) {
          publicURL
        }
      }
    `}
    render={({ banner, damage }) => (
      <>
        {includeSeo && (
          <SEO
            description="Sometimes, simple things turn out to be complex. Like spray paint!"
            image={banner.publicURL}
            slug="paint-job"
            title="Finally painted my guitar!"
          />
        )}
        <Card className="px-0">
          <img
            alt="My newly painted purple guitar resting on the canvas cloth I painted it on. It shows a few outlines of my guitar and looks cool."
            className="d-block img-banner mb-2 mx-auto"
            src={banner.publicURL}
          />
          <h2 className="mb-0 mt-3 text-center">Paint Job</h2>
          <span className="mb-3 text-center">14 March 2021</span>
          <div className="body mx-auto px-2 px-sm-0">
            <p>
              Looking back, I can&apos;t help but laugh at the fact that in February of 2020, I was in the paint aisle trying to figure out what kind of respirator I needed to spray paint my guitar. I left that day empty-handed, definitely not thinking that in the near future, our lives would change for the better part of a year. By the time the virus showed up, there were no masks anywhere in stock. I had to wait a few months, but finally snagged one off of Amazon.
            </p>
            <p>
              How did we get here you ask? Well, have you ever fallen prey to your destructive tendencies and regretted that time you took a screwdriver to the perfectly fine cherry red paint job of your electric guitar? No, just me?
            </p>
            <div className="mx-auto pb-4 text-center">
              <img
                alt="Me in a gas mask holding up the body of my disassembled, badly scratched up guitar"
                className="d-block mb-0 mx-auto"
                src={damage.publicURL}
              />
              <small>Almost all of this damage was caused by teenage stupidity.</small>
            </div>
            <p>
              When it got warm enough, I sprayed a piece of scrap wood to help me decide on a color (which ended up being Rustoleum Gloss Grape) and headed to my parents&apos; house to borrow their garage and do it for real. That&apos;s when things start started to go south, as I kept making mistake after mistake. The paint would peel and little air bubbles would form, and yet, I kept on sanding in between to try and even it out which inevitably made things worse. After three days of this, I gave up, packed up, and left in a huff.
            </p>
            <p>
              When I got home, I bought an orbital sander and removed the old paint so I could start over. My current residence is garage-less, meaning the rest of the process was at the whim of the weather. I got the hang of the process eventually, but I had to burn through a lot of raw patience and persistence to get there. There were times the whole thing felt impossible. I briefly considered burning the damn thing, but I&apos;m glad I didn&apos;t as this guitar means a great deal to me.
            </p>
          </div>
        </Card>
      </>
    )}
  />
);

export default PaintJob;
